<template>
  <div class="box">
    <div class="content">
      <div class="head" style="overflow: hidden">
        <van-uploader
          multiple
          :max-count="1"
          :after-read="afterRead"
          preview-size="50"
          :deletable="false"
          :max-size="1024 * 1024"
          image-fit="cover"
          @oversize="maxImg"
        >
          <van-image
            width="110"
            height="110"
            round
            fit="cover"
            :src="userInfo.userPhotoFullPath"
          />
        </van-uploader>
      </div>
      <van-cell
        title="昵称"
        is-link
        :value="userInfo.username"
        @click="changeName"
      />
      <van-cell title="手机" is-link :value="userInfo.mobile" />
    </div>
    <div class="logout" @click="logout">退出当前账号</div>
    <van-dialog
      v-model="show"
      title="修改昵称"
      show-cancel-button
      @confirm="sub"
    >
      <input type="text" v-model="form.name" class="input" />
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import url from "@/service/url-config";
export default {
  data() {
    return {
      token: "",
      userInfo: {
        username: "登录/注册",
        userPhotoFullPath: require("@/img/my/user-photo.png"),
      },
      fileList: [],
      show: false,
      form: {
        name: "",
      },
    };
  },
  created() {
    this.$http.get(url.userInfo).then((res) => {
      this.userInfo = res.data;
      if (!this.userInfo.userPhotoFullPath) {
        this.userInfo.userPhotoFullPath = require("@/img/my/user-photo.png");
      }
    });
  },
  methods: {
    logout() {
      this.$router.push("login");
      window.localStorage.clear();
    },
    changeName() {
      this.show = true;
    },
    sub() {
      this.$http.put(url.upUser, { username: this.form.name }).then((res) => {
        if (res.code == 200) {
          this.show = false;
          this.userInfo.username = this.form.name;
          Toast("修改成功");
        }
      });
    },
    afterRead(file) {
      console.log(file);
      const fd = new FormData();
      fd.append("file", file.file);
      this.$http.post(url.photo, fd).then((res) => {
        if (res.code == 200) {
          this.$http.put(url.upUser, {
            userPhoto: res.data.fullPath,
          });
          this.userInfo.userPhotoFullPath =
            res.data.endpoint + res.data.fullPath;
        } else {
          Toast.fail("上传失败");
        }
      });
    },
    toMessage() {
      this.$router.push("message");
    },
    maxImg() {
      Toast.fail("请大小限制在1M");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: calc(100vh - 80px);
  padding-top: 80px;
  .content {
    width: 375px;
    height: 333px;
    background: #ffffff;
    border-radius: 10px;
    .head {
      width: 110px;
      height: 110px;
      margin: 0 auto;
      padding-top: 15px;
      margin-bottom: 30px;
    }
  }
  .input {
    display: block;
    margin: 0 auto;
  }
  .logout {
    width: 325px;
    height: 44px;
    background: #113fc4;
    border-radius: 22px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 44px;
    position: fixed;
    bottom: 30px;
    left: 50%;
    margin-left: -160px;
  }
}
</style>
